import { FieldErrors, FieldValues } from 'react-hook-form'
import * as yup from 'yup'

import { darkGrey, errorRed, mediumGrey } from '@ui/theme/colors'

import { SPECIAL_CHARACTERS_OR_NUMBERS_REG_EX } from './constants'

const getNestedValue = (obj: any, path: string) => {
  return path.split('.').reduce((acc, part) => acc && acc[part], obj)
}

export const getFieldError = (errors: FieldErrors<FieldValues>, name: string) => {
  const error = getNestedValue(errors, name)
  const hasError = !!error
  const errorMessage = error?.message?.toString()
  return { hasError, errorMessage }
}

export const getColorForState = ({ error, disabled }: { error: boolean; disabled: boolean }) =>
  disabled ? mediumGrey : error ? errorRed : darkGrey

export const getNameValidator = (label: string) => {
  return yup
    .string()
    .required(`${label} is required`)
    .test('noSpecialCharsOrNumbers', 'Cannot contain special characters or numbers', (value) => {
      return !SPECIAL_CHARACTERS_OR_NUMBERS_REG_EX.test(value)
    })
}
